import { settings } from "./config";

import { InMemoryCache, ApolloClient } from "@apollo/client";
import { createApolloProvider } from "@vue/apollo-option";

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  uri: settings.graphqlURL,
});

export const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});
