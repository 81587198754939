<template>
  <div>
    <h4>Fetching results...</h4>
    <lottie :options="lottieOptions" :height="400" :width="400" />
  </div>
</template>

<script>
import Lottie from "./Lottie.vue";

import animation from "../assets/animation.json";

export default {
  components: {
    Lottie,
  },
  setup() {
    const lottieOptions = { animationData: animation };
    return {
      lottieOptions,
    };
  },
};
</script>