<template>
  <div class="location-picker" v-bind:class="{ addPadding: !isEditing }">
    <country-flag v-if="!isEditing" :country="countryCode" size="small" />
    <input
      class="selectedLocation"
      type="text"
      placeholder="Location"
      v-model="text"
      @focus="isEditing = true"
      @blur="isEditing = false"
      @keydown="cancelTimer"
      @keyup="startInput"
    />
  </div>
  <transition name="list">
    <div v-if="isEditing" class="dropdown-content">
      <transition-group name="items">
        <div v-bind:key="i" v-for="(location, i) in locations" class="item">
          <country-flag :country="location.countryCode" size="small" />
          <a href="#" @click="pickLocation(location)">
            {{ location.canonicalName }}
          </a>
        </div>
      </transition-group>
    </div>
  </transition>
</template>

<script>
import CountryFlag from "vue-country-flag-next";
import { computed, ref } from "vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { GEO_QUERY } from "../queries";

export default {
  name: "LocationPicker",
  components: { CountryFlag },
  props: ["modelValue"],
  setup(props, { emit }) {
    const isEditing = ref(false);
    const countryCode = ref("US");
    const text = ref(props.modelValue);
    const inputTimeout = 1200;
    let typingTimer;

    // Timeouts for typing
    const startInput = () => {
      typingTimer = setTimeout(finishInput, inputTimeout);
    };

    const finishInput = () => {
      location.value = text.value;
    };

    const cancelTimer = () => {
      clearTimeout(typingTimer);
    };

    // Location model two-way binding
    const location = computed({
      get: () => props.modelValue,
      set: (value) => emit("update:modelValue", value),
    });

    // Query
    const { result } = useQuery(GEO_QUERY, { input: location });
    const locations = useResult(result);

    const pickLocation = (loc) => {
      text.value = loc.canonicalName;
      location.value = loc.canonicalName;
      countryCode.value = loc.countryCode;
      isEditing.value = false;
    };

    return {
      text,
      countryCode,
      locations,
      location,
      isEditing,
      pickLocation,
      startInput,
      finishInput,
      cancelTimer,
    };
  },
};
</script>

<style scoped>
.items-enter-from,
.items-leave-to,
.list-enter-from,
.list-leave-to {
  opacity: 0;
}

.items-enter-active,
.items-leave-active,
.list-enter-active,
.list-leave-active {
  transition: opacity 0.5s ease-in;
}

.location-picker {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-left: 10px;
}

.location-picker {
  padding-left: 0px;
}

.selectedLocation {
  padding-left: 5px;
  width: 100%;
}

.item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

.dropdown {
  position: relative;
  display: inline-block;
  font-size: 13px;
  width: 100%;
}

.dropdown-content {
  display: block;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  z-index: 1;
  text-align: left;
  box-shadow: 0px 16px 12px 0px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* Links inside the dropdown */
.dropdown-content a {
  font-size: 13px;
  color: black;
  padding: 8px 5px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content div:hover {
  background-color: #ddd;
  border-radius: 5px;
}

.addPadding {
  padding-left: 10px;
}
</style>