import gql from "graphql-tag";

export const SERP_QUERY = gql`
  query RealTimeSERP($input: SERPInput!) {
    serp(input: $input) {
      body
      json
    }
  }
`;

export const GEO_QUERY = gql`
  query findTargets($input: String!) {
    findTargets(name: $input) {
      canonicalName
      countryCode
      population
    }
  }
`;
