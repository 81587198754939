export const config = {
    description: `Scrape any website with no blocking. Commercial service that delivers 15M IP addresses`,
    author: `spider.com`,
};

const isBrowser = () => typeof window !== `undefined`;

const getDomain = () => window.location.host.replace(/^([^.]+)./, ``);
const recaptchaKey = () => {
    if (window.location.host === `secure.spider.com`) {
        return `6LdpTowbAAAAAM4Yfm6gMvTKCvyWpWDy2JZD7058`;
    }
    if (window.location.host === `secure.stage.spider.com`) {
        return `6Lf3aIwbAAAAAAWubB1kfShlBHc9xnaA8Kjs9MUF`;
    }

    return `6LdmUIwbAAAAANsZZFLMh6s3hhsdDcw_Lxgf5PNO`;
};

const makeSettings = () => {
    let settings = {};
    if (isBrowser()) {
        settings = {
            graphqlURL: `//demo.spider.com/_graphql/www/`,
            docsURL: `//docs.${getDomain()}/`,
            recaptchaKey: recaptchaKey(),
            ...settings,
        };
    }
    return settings;
};

export const settings = makeSettings();
