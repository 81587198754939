<template>
  <div class="row">
    <div class="search">
      <input
        v-model="query"
        type="text"
        placeholder="Keyword"
        @keyup.enter="updateInput()"
      />
    </div>
    <div class="location">
      <location-picker v-model="location" />
    </div>
    <div class="domain">
      <select v-model="domain" name="domain">
        <option v-bind:key="d.k" v-for="d in domains" :value="d.v">
          {{ d.k }}
        </option>
      </select>
    </div>
    <div
      class="btn"
      @click="updateInput()"
      v-bind:class="{ disabled: loading }"
    >
      <img src="../assets/images/icon-search.svg" alt="" class="search-icon" />
      <div class="btext">
        {{ buttonText }}
      </div>
    </div>
  </div>
</template>

<script>
import LocationPicker from "./LocationPicker.vue";
import { reactive, watch, ref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { SERP_QUERY } from "../queries";

export default {
  components: { LocationPicker },
  props: ["modelValue"],
  setup(_, { emit }) {
    // Some constants.
    const buttonText = "Test search";
    const domains = [
      { v: "www.google.com", k: "Google" },
      { v: "www.bing.com", k: "Bing" },
    ];

    // Form input default values
    const query = ref("vegan restaurants");
    const location = ref("New York,New York,United States");
    const domain = ref("www.google.com");

    // Default query values.
    const input = reactive({
      domain: "www.google.com",
      query: "vegan restaurants",
      canonicalName: "New York,New York,United States",
      userAgent: "desktop",
    });

    // Default state of search results.
    const state = {
      serp: {},
      loading: false,
    };

    const updateInput = () => {
      input.query = query.value;
      input.canonicalName = location.value;
      input.domain = domain.value;
    };

    const { loading, onResult } = useQuery(SERP_QUERY, {
      input,
    });

    watch(loading, (newVal) => {
      emit("serpLoading", newVal);
    });

    onResult(({ data }) => {
      if (!data) {
        return;
      }
      state.serp = data.serp;
      emit("serpUpdate", state);
    });

    return {
      domains,
      buttonText,
      input,
      query,
      location,
      domain,
      updateInput,
      loading,
    };
  },
};
</script>

<style>
input {
  border: 0px;
  font-size: 13px;
  width: calc(100% - 33px);
  height: 40px;
  padding-left: 10px;
}

.search {
  flex-basis: 45%;
  border-right: solid rgb(179, 179, 179) 1px;
  padding-right: 10px;
}

.search-icon {
  width: 14px;
  height: 14px;
  padding: 4px;
}

.location {
  flex-basis: 65%;
  border-right: solid rgb(179, 179, 179) 1px;
  padding-left: 0px;
}

.domain {
  text-align: center;
}

.btn {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  flex-basis: content;
  background-color: #5fcf3f;
  margin-right: -12px;
  margin-top: -12px;
  margin-bottom: -12px;

  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 0;
  padding: 10px;
  cursor: pointer;
}

.btn:hover {
  background-color: #31821a;
}

.btn:active {
  background-color: #47b536;
}

.disabled {
  background-color: #aaa !important;
  cursor: default;
}

.btext {
  align-content: center;
  text-align: center;
  color: white;
  font-variant-caps: all-petite-caps;
  font-weight: 700;
  padding-right: 8px;
}

.search input {
  background-image: url("../assets/icon_spider.svg");
  background-repeat: no-repeat;
  background-position: 10px 15px;
  border: 0px;
  padding-left: 30px;
}

pre {
  margin: 0px;
}

select {
  height: 40px;
  font-weight: 500;
  background-color: #fff;
  border: 0px;
  font-size: 13px;
  margin-right: 10px;
  margin-left: 10px;
}

@media (max-width: 800px) {
  .row {
    flex-flow: column wrap;
  }

  .search {
    flex-basis: 100%;
    border-right: 0px;
    padding-right: 0px;
    border-bottom: solid rgb(179, 179, 179) 1px;
  }

  .location {
    flex-basis: 100%;
    border-right: 0px;
    padding-left: 0px;
  }
  .domain {
    flex-basis: 47%;
  }

  .btn {
    flex-basis: 47%;
    margin: 0px;
    border-radius: 5px;
  }
}
</style>