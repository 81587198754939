<template>
  <div class="outer">
    <div class="container">
      <div class="head"></div>
      <div class="content">
        <h3>{{ header }}</h3>
        <p>{{ message }}</p>
        <div class="inputs">
          <FormInputs
            @serpUpdate="onSerpUpdate($event)"
            @serpLoading="onSerpLoading($event)"
          />
        </div>
        <loading v-show="state.loading" />
        <div v-if="!state.loading" class="row result">
          <div class="html">
            <iframe
              id="frame"
              title="Search engine result"
              frameBorder="0"
              scrolling="no"
              :srcDoc="state.serp.body"
            />
          </div>
          <div class="parsed">
            <highlightjs language="JSON" :code="state.serp.json" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormInputs from "./FormInputs.vue";
import { reactive } from "vue";

import hljsVuePlugin from "@highlightjs/vue-plugin";
import "highlight.js/styles/monokai-sublime.css";
import "highlight.js/lib/common";
import Loading from "./Loading.vue";

export default {
  name: "Main",
  components: {
    FormInputs,
    highlightjs: hljsVuePlugin.component,
    Loading,
  },
  setup() {
    const header = "Spider® Real-Time Crawler";
    const message =
      "Try out our Live Demo. Scrape any data from Google with Spider® SERP API";

    const state = reactive({
      serp: { body: "body", json: "json" },
      loading: true,
    });

    const onSerpUpdate = (event) => {
      state.serp = event.serp;
    };

    const onSerpLoading = (loading) => {
      state.loading = loading;
    };

    return {
      state,
      header,
      message,
      onSerpUpdate,
      onSerpLoading,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.outer {
  display: flex;
  justify-content: center;
}

.container {
  width: 80%;
  max-width: 1000px;
  background-color: #e7f3ff;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  margin-top: 0px;
}

.inputs {
  width: 100%;
}

.head {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #d9ebfe;
  height: 35px;
}

.content {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  padding: 20px;
}

.content h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 150%;
  color: #13497f;
  margin: 30px 0 10px;
}

.content p {
  width: 70%;
  text-align: center;
  margin: 10px auto 50px;
  line-height: 24px;
}

.row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  background-color: white;
  padding: 12px;
  border-radius: 5px;
  text-align: left;
  margin-bottom: 20px;
}

.result {
  max-height: 640px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 15px;
  padding-top: 10px;
}

.html {
  border-radius: 15px;
  width: 50%;
  margin-top: 15px;
  padding: 5px;
  align-content: center;
  overflow-y: scroll;
  overflow-x: hidden;
  background: white;
  min-height: 480px;
}
.parsed {
  padding: 0px;
  border-radius: 15px;
  min-height: 480px;
  text-align: left;
  font-size: 10px;
  height: auto;
  overflow-x: hidden !important;
  width: 50%;
  align-content: center;
}

#frame {
  pointer-events: none;

  width: 200%;
  height: 800%;
  border: 0;

  -ms-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -o-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  transform: scale(0.5);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

@media (max-width: 800px) {
  .row {
    flex-flow: row wrap;
  }

  .result {
    max-height: 1280px;
  }

  .parsed {
    max-height: 640px;
    width: 100%;
  }

  .html {
    margin-bottom: 30px;
    max-height: 640px;
    width: 100%;
  }
}
</style>
